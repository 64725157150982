import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Toast from '.';

export const ToastContext = React.createContext({
  show: null,
});

const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  const value = useMemo(
    () => ({
      show: ({ message, action, duration, variant = 'info' }) =>
        toastRef?.current?.show({ message, action, duration, variant }),
    }),
    [toastRef]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toast ref={toastRef} />
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastProvider;
