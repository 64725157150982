import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

const PageLoading = () => (
  <div className={styles.container}>
    <CircularProgress />
  </div>
);

export default PageLoading;
