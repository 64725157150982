import React, { useImperativeHandle, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const DEFAULT_DURATION = 3000;

const Toast = (props, ref) => {
  const [toastInfo, setToastInfo] = useState({
    message: '',
    variant: 'info',
    action: '',
    duration: DEFAULT_DURATION,
    show: false,
  });

  useImperativeHandle(ref, () => ({
    show: ({ message, duration = DEFAULT_DURATION, action, variant }) => {
      setToastInfo({
        message,
        variant,
        action,
        duration,
        show: true,
      });
    },
  }));

  const handleClose = () => {
    setToastInfo((curr) => ({
      ...curr,
      show: false,
    }));
  };

  return (
    <Snackbar open={toastInfo.show} autoHideDuration={toastInfo.duration} onClose={handleClose}>
      <MuiAlert elevation={6} variant="filled" onClick={handleClose} severity={toastInfo.variant}>
        {toastInfo.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default React.forwardRef(Toast);
