import React, { useCallback, useMemo, useState } from 'react';
import { InstructorAuthContextValueType } from './types';

export const InstructorAuthContext = React.createContext<InstructorAuthContextValueType>({});

export const InstructorAuthProvider = ({ children }) => {
  const [instructorToken, setInstructorToken] = useState(null);

  const saveAuthToken = useCallback((token: string) => {
    setInstructorToken(token);
    localStorage.setItem('instructor-tkn', token);
  }, []);

  const value = useMemo(
    () => ({ isLoggedIn: Boolean(instructorToken) || Boolean(localStorage.getItem('instructor-tkn')), saveAuthToken }),
    [instructorToken, saveAuthToken]
  );

  return <InstructorAuthContext.Provider value={value}>{children}</InstructorAuthContext.Provider>;
};
