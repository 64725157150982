import React, { useState } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = React.createContext({ isLoggedIn: false });

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => Cookies.get('admin'));

  return <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>{children}</AuthContext.Provider>;
};
