import React, { Suspense, lazy } from 'react';
// theme
import PageLoading from '@components/feedback/PageLoading';
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import ToastProvider from './components/feedback/Toast/DefinedToast';
import { AuthProvider } from './contexts/AuthContext';
import { InstructorAuthProvider } from './contexts/InstructorAuthContext';

const Router = lazy(() => import('./routes/admin'));
const EventRouter = lazy(() => import('./routes/event'));
const InstructorRouter = lazy(() => import('./routes/instructor'));
const TranscoderRouter = lazy(() => import('./routes/transcoder'));
// ----------------------------------------------------------------------

const renderRouter = () => {
  switch (process.env.REACT_APP_ADMIN) {
    case 'event':
      return <EventRouter />;
    case 'instructor':
      return <InstructorRouter />;
    case 'transcoder':
      return <TranscoderRouter />;
    default:
      return <Router />;
  }
};

export default function App() {
  return (
    <AuthProvider>
      <InstructorAuthProvider>
        <ThemeProvider>
          <ToastProvider>
            <ScrollToTop />
            <StyledChart />
            <Suspense fallback={<PageLoading />}>{renderRouter()}</Suspense>
          </ToastProvider>
        </ThemeProvider>
      </InstructorAuthProvider>
    </AuthProvider>
  );
}
